import {ResizableImage} from '~/components/data-class/data-class'
import Serializable from '~/utils/serializable'

export enum ResourceType {
    Video = 0,
    PDF = 1,
    Image = 2,
    Other = 3
}

export class DseResourceGroup extends Serializable {
    _id: number = -1
    title: string = ''
    description: string = ''
    member_id: string = ''

    subject: string = ''
    meta_title: string = ''
    meta_description: string = ''
    url_slug: string = ''

    thumbnail_url: string = ''

    like_count: number = 0
    view_count: number = 0

    types: ResourceType[] = []
    resources: DseResource[] = []

    created: number = 0
    edited: number = 0

    hidden: boolean = false

    //private
    thumbnail?: ResizableImage
    like?: string[] = []
    bookmark?: string[] = []

    //out
    liked?: boolean
    bookmarked?: boolean
    display_name?: string
}

export class DseResourceGroupEditable extends Serializable {
    title: string = ''
    description: string = ''
    member_id: string = ''

    subject: string = ''
    meta_title: string = ''
    meta_description: string = ''
    url_slug: string = ''

    thumbnail_url: string = ''
    resources: DseResource[] = []

    hidden: boolean = false
}

export class DseResource extends Serializable {
    title: string = ''
    type: ResourceType = ResourceType.Video
    size: number = 0

    // for dropzone
    id: number
}

export enum VideoType {
    YouTube = 0,
    Vimeo = 1
}

export class VideoResource extends DseResource {
    video_type: VideoType = VideoType.YouTube
    video_id: string = ''

    constructor() {
        super()
        this.type = ResourceType.Video
    }
}

export class ImageResource extends DseResource {
    image_url: string = ''

    constructor() {
        super()
        this.type = ResourceType.Image
    }
}

export class FileResource extends DseResource {
    file_url: string = ''
    size: number = 0
}

export class PdfResource extends FileResource {
    constructor() {
        super()
        this.type = ResourceType.PDF
    }
}

export class OtherResource extends FileResource {
    constructor() {
        super()
        this.type = ResourceType.Other
    }
}
